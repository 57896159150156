import React from "react";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Bisnode from "../../../assets/Kredit_AA_vit_enkel.svg";
import GetIntlSinglePrimitive from "../../utils/GetIntlSinglePrimitive";

export default function Footer() {
  const intl = useIntl();
  return (
    <footer className="footer has-background-black-ter">
      <div className="content has-text-centered">
        <div className="columns is-centered">
          <div className="column">
            <p>
              <Link className="has-text-light" to="/contact">
                {GetIntlSinglePrimitive(intl, "links.link5")}
              </Link>
            </p>
            <p>
              <a className="has-text-light" href="mailto:info@dekiru.se">
                info@dekiru.se
              </a>
            </p>
            <p> Pipersgatan 33, 112 28 Stockholm</p>
            <p> © Dekiru Solutions AB {new Date().getFullYear()}</p>
          </div>
        </div>
        <div className="footer__bisnode">
          <Bisnode />
        </div>
      </div>
    </footer>
  );
}
