import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import GetIntlArrayPrimitive from "../../utils/GetIntlArrayPrimitive";
import GetIntlSinglePrimitive from "../../utils/GetIntlSinglePrimitive";
import SEOMetaTags from "../seo/seo";

export default function Header() {
  const intl = useIntl();
  const lang = intl.locale;
  const keywords = GetIntlArrayPrimitive("metaData.keywords", intl.messages);
  const description = GetIntlSinglePrimitive(intl, "metaData.description");

  const imageQuery = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "dekiru/dekiru.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 628, layout: FIXED)
        }
      }
    }
  `);

  return (
    <div className="header-container">
      <SEOMetaTags description={description} image={imageQuery.logo.childImageSharp.gatsbyImageData} keywords={keywords} lang={lang} title="Dekiru Solutions" />
    </div>
  );
}
