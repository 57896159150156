/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

interface Props {
  description?: string;
  lang?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image?: any;
  keywords?: string[];
}

export default function SEOMetaTags({ description = "", lang = "sv", meta = [], title, image: metaImage, keywords }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
            lang
          }
        }
      }
    `
  );

  const htmlLang = lang || site.metaData.lang;
  const metaKeywords = keywords || site.siteMetadata.keywords;
  const metaDescription = description || site.siteMetadata.description;

  const image = metaImage && metaImage.src ? `${site.siteMetadata.siteUrl}${metaImage.src}` : null;

  return (
    <Helmet
      htmlAttributes={{
        htmlLang
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `google-site-verification`,
          content: `TU887zzljguNRMJs1_OgIO3M4HOdcC81n_lZ_6ROzAk`
        },
        {
          name: "keywords",
          content: metaKeywords.join(",")
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: image
                },
                {
                  property: "og:image:width",
                  content: metaImage.width
                },
                {
                  property: "og:image:height",
                  content: metaImage.height
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image"
                }
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary"
                }
              ]
        )
        .concat(meta)}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate={`${site.siteMetadata.title}`}
    >
      <link
        crossOrigin="anonymous"
        href="https://use.fontawesome.com/releases/v5.4.1/css/all.css"
        integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz"
        rel="stylesheet"
      />
    </Helmet>
  );
}
