import React from "react";
import { Link } from "gatsby";
import { changeLocale, IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import { Location as HLocation } from "history";
import GetIntlArrayObject from "Src/utils/GetIntlArrayObject";

import GetIntlSinglePrimitive from "../../utils/GetIntlSinglePrimitive";

export type WindowLocation = Window["location"] & HLocation;

const languageName = {
  en: "English",
  sv: "Svenska"
};

interface IntlContextConsumerProps {
  languages: string[];
  language: string;
}

// Copied interface (import doesn't work) from react-router types
interface LinkGetProps {
  isCurrent: boolean;
  isPartiallyCurrent: boolean;
  href: string;
  location: WindowLocation;
}

const isActive = ({ href, location }: LinkGetProps) => {
  let isActiveMatch = false;
  let url = location.pathname as string;
  url = url.substring(3).slice(0, -1);
  if (url.length === 0) {
    url = url.concat("/");
  }
  if (url === href) {
    isActiveMatch = true;
  }
  return isActiveMatch ? { className: "active-header-link" } : {};
};

export default function Links() {
  const intl = useIntl();
  const _lang = intl.locale;
  const [burgerIsActive, setisActive] = React.useState(false);
  const routes = GetIntlArrayObject("routes.route", intl.messages);

  return (
    <nav aria-label="main navigation" className="navbar has-box-shadow is-fixed-top is-spaced" role="navigation">
      <div className="navbar-brand">
        <Link className="navbar-item " to="/">
          <img alt="Logo" height="200" src="/Dekiru_Logo.svg" width="200" />
        </Link>
        <a
          aria-expanded="false"
          aria-label="menu"
          className={`navbar-burger ${burgerIsActive ? "is-active" : ""}`}
          data-target="navbarBasicExample"
          role="button"
          onClick={() => setisActive(!burgerIsActive)}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div className={`navbar-menu ${burgerIsActive ? "is-active" : ""}`}>
        <div className="navbar-start ml-6-on-mobile">
          {routes.map((_r, i) => {
            return (
              <div key={i} className="navbar-item is-size-5">
                <Link className="header-link" getProps={isActive} to={GetIntlSinglePrimitive(intl, `routes.route${i}`)}>
                  {GetIntlSinglePrimitive(intl, `links.link${i}`)}
                </Link>
              </div>
            );
          })}
        </div>
        <div className="navbar-end">
          <div className="navbar-item is-size-6 has-text-centered-mobile">
            <IntlContextConsumer>
              {({ languages, language: _currentLocale }: IntlContextConsumerProps) =>
                languages.map((lan) => (
                  <a key={lan} className="mr-4 header-link" onClick={() => changeLocale(lan)}>
                    {languageName[lan as "en" | "sv"]}
                  </a>
                ))
              }
            </IntlContextConsumer>
          </div>
        </div>
      </div>
    </nav>
  );
}
