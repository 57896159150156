import React from "react";
import CookieConsent from "react-cookie-consent";
import { useIntl } from "gatsby-plugin-intl";

import GetIntlSinglePrimitive from "../utils/GetIntlSinglePrimitive";

import Footer from "./footer/footer";
import Header from "./header/header";
import Links from "./links/links";

import "../styles/site.scss";

interface Props {
  children: React.ReactNode;
}

export default function Layout({ children }: Props) {
  const intl = useIntl();
  return (
    <>
      <Header />
      <CookieConsent
        enableDeclineButton
        buttonClasses="cookie__button"
        buttonText={GetIntlSinglePrimitive(intl, "cookiebanner.acceptButton")}
        containerClasses="cookie"
        cookieName="gatsby-gdpr-google-analytics"
        declineButtonClasses="cookie__button"
        declineButtonText={GetIntlSinglePrimitive(intl, "cookiebanner.declineButton")}
        expires={150}
        location="bottom"
      >
        {GetIntlSinglePrimitive(intl, "cookiebanner.banner")}
      </CookieConsent>
      <Links />
      <main>{children}</main>
      <Footer />
    </>
  );
}
