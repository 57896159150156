import { IntlShape } from "gatsby-plugin-intl";

/**
 * Wrapper for retrieving prop in intl language file containing string value
 * @param intl
 * @param id
 */
export default function GetIntlSinglePrimitive(intl: IntlShape, id: string) {
  return intl.formatMessage({ id: id });
}
