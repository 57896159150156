/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Wrapper for retrieving prop in intl language file containing array of string values
 * @param keyword - keyword to index json prop on language file
 * @param arr - array containing all values in language file
 */
export default function GetIntlArrayPrimitive(keyword: string, arr: any) {
  const text = Object.keys(arr).flatMap((f) => {
    if (f.includes(keyword)) {
      return arr[f];
    }
    return [];
  });

  return text;
}
