/* eslint-disable @typescript-eslint/no-explicit-any */
type _T1 = { [propName: string]: string };
type T2 = { index: string | undefined; [propName: string]: string | undefined };
/**
 * Wrapper for retrieving prop in intl language file containing array of objects
 * @param keyword - keyword to index json prop on language file
 * @param obj - object containing all values in language file
 */
export default function GetIntlArrayObject(keyword: string, obj: any) {
  const regexExtractNumber = /\d+/;

  const extractedNestedProps: T2[] = Object.keys(obj).flatMap((item) => {
    if (!item.includes(keyword) || item.split(".").length > 3) {
      return [];
    }
    const indexNumber = item.match(regexExtractNumber)?.pop();
    const propName = item.split(".").pop() as string;

    return { index: indexNumber, [propName]: obj[item] };
  });

  const skipIndex: string[] = [];
  const result: any[] = [];

  extractedNestedProps.forEach((t) => {
    if (skipIndex.some((si) => si === t.index)) {
      return;
    }
    const filteredNestedProps = extractedNestedProps.filter((tt) => tt.index === t.index);

    if (filteredNestedProps && filteredNestedProps.length > 0) {
      let mergedObject: any;
      filteredNestedProps.forEach((f) => (mergedObject = { ...mergedObject, ...f }));

      result.push(mergedObject);
    }

    skipIndex.push(t.index as string);
  });

  return result as any[];
}
